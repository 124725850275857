import config from '~/authentication/lib/config';
import { REQUEST_HEADERS } from '~/services/headers';
// --- internal imports ------

async function getTradersUserData(token) {
	try {
		const baseUrl = config.userbaseServerBaseUrl;
		return await fetch(`${baseUrl}/api/user/`, {
			method: 'GET', 
			headers: {
				...REQUEST_HEADERS,
				'authorization': `Bearer ${token}`
			}
		})
			.then((result) => result.json())
			.then((response) => response?.data?.user);

	}
	catch(e) {
		console.error(e);
		return null;
	}
}

export default getTradersUserData;
