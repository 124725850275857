import styled from '@emotion/styled';
// --- external imports ------

import TextContainer from './TextContainer';
// --- local imports ------

const WhiteTextContainer = styled(TextContainer)`
	color: #fffbdd;
`;

export default WhiteTextContainer;
