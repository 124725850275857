export const DEPARTMENT_OPTIONS = [
	'Training and Development',
	'Human Resources',
	'Sales',
	'Administration',
	'Other'
];

export const NAME_MIN_LENGTH = 2;
export const NAME_MAX_LENGTH = 40;

// https://www.rfc-editor.org/errata_search.php?rfc=3696
export const EMAIL_MAX_LENGTH = 254;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 30;

export const USER_STATE = {
	CHECKING_USER: undefined,
	NOT_LOGGED_IN: null
};

export const USER_LOADING_STATE = {
	LOADING_FROM_FIREBASE: 0,
	FAILED_TO_LOAD_FROM_FIREBASE: 1,
	LOADING_FROM_TRADERS_USERBASE: 2,
	FAILED_TO_LOAD_FROM_TRADERS_USERBASE: 3,
	SUCCESS: 4
};
