

export function relPreload(url, asType) {
	return new Promise((resolve) => {
		const link = document.createElement('link');

		const fileExt = (() => {
			const urlSplit = url.split('.');
			return urlSplit[urlSplit.length - 1];
		})();

		link.href = url;
		link.rel = 'preload';
		link.onload = () => resolve(url);

		if (asType || this?.asType) {
			link.as = this?.asType ?? asType;
		}

		if (this.crossOrigin) {
			link.crossOrigin = this.crossOrigin;
		}

		if (this.mimeType) {
			link.type = this.mimeType;
		} else {
			switch (fileExt) {
			case (fileExt in ['woff', 'woff2']):
				link.type = `font/${fileExt}`;
				break;
			default:
				break;
			}
		}

		document.getElementsByTagName('head')[0].appendChild(link);
	});
}


export function groupByPath(loaders, { category }) {
	return Object.entries(loaders)
		.reduce((accum, [key, value]) => {
			const regex = new RegExp(`.+${category}[\\/\\\\]([\\w\\d\\-_]+)`);

			const [, newKey] = key.match(regex) ?? [null, key];

			if (Array.isArray(accum[newKey])) {
				accum[newKey].push(value);
			} else {
				accum[newKey] = [value];
			}

			return accum;
		}, {});
}


export function bindImports(loaders, { category, options }) {
	function updateCB(callback, { ref, status, groupLoaders, results  }) {
		callback?.({
			...ref,
			status,
			completed: `${results.length ?? 0}/${groupLoaders.length}`,
			progress: Math.ceil((results.length * 100) / groupLoaders.length),
		});
	}

	function initPreloader() {
		return (module) => {
			if (options?.preloader === false) {
				return Promise.resolve(module);
			}

			if (typeof options?.preloader === 'function') {
				return options
					?.preloader?.(
						module?.default?.src ?? module?.default,
						options?.preloadType
					);
			}

			return relPreload(
				module?.default?.src ?? module?.default,
				options?.preloadType
			);
		};
	}

	function updateProgress(callback, resolve, { ref, results, groupLoaders}) {
		return (r) => {
			results.push(r);

			updateCB(
				callback,
				{
					ref,
					results,
					groupLoaders,
					status: 'done',
				}
			);

			if (results.length === groupLoaders.length) {
				resolve(results);
			}
		};
	}

	return Object.entries(loaders)
		.reduce((accum, [groupName, groupLoaders]) => {
			function groupCombinedLoader(callback) {
				return new Promise(
					(resolve) => {
						const results = [];

						const ref = {
							category,
							groupName,
						};

						updateCB(
							callback,
							{
								ref,
								results,
								groupLoaders,
								status: 'starting',
							}
						);

						groupLoaders?.forEach((loader) => {
							updateCB(
								callback,
								{
									ref,
									results,
									groupLoaders,
									status: 'starting',
								}
							);

							loader()
								?.then(initPreloader())
								?.then(updateProgress(callback, resolve, {
									ref,
									results,
									groupLoaders
								}));
						});
					}
				);
			}

			accum[groupName] = groupCombinedLoader;

			return accum;
		}, {});
}


export function deduceLoaders(loaders, category, options) {
	if (options?.groupByCategoryPath === false) {
		const grouped = {
			[options?.groupName ?? category]: Object.values(loaders),
		};

		return bindImports(grouped, { category, options });
	}

	if (typeof options?.postGroup === 'function') {
		return bindImports(
			options?.postGroup?.(groupByPath(loaders, { category })),
			{ category, options },
		);
	}

	return bindImports(
		groupByPath(loaders, { category }),
		{ category, options },
	);
}
