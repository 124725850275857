import config from '~/authentication/lib/config';
import { REQUEST_HEADERS } from '~/services/headers';
// --- internal imports ------

async function resendRegistrationEmail(email) {
	const baseUrl = config.userbaseServerBaseUrl;
	const url = `${baseUrl}/api/user/resendregistrationemail/`;
	const response = await fetch(url, {
		method: 'POST', 
		headers: REQUEST_HEADERS,
		body: JSON.stringify({ email })
	});

	const result = await response.json();

	return result;
}

export default resendRegistrationEmail;
