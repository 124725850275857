import React, {useState} from 'react';
import styled from '@emotion/styled';
// --- external imports ------

import eyeInactiveProjection from '~/assets/images/auth/eye-inactive.png';
import eyeActiveProjection from '~/assets/images/auth/eye-active.png';
// --- internal imports ------

import Error from './Error';
import Label from './Label';
import CoreInput from './CoreInput';
// --- local imports ------

function Password(props) {
	const [password, setPassword] = useState('');
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	function handleOnChangeShowPasswordButton(event) {
		setIsPasswordVisible(event.target.checked);
	}

	function handleOnChangePassword(event) {
		setPassword(event.target.value);
	}

	const shouldUsePasswordType = (!isPasswordVisible && password?.length > 0);

	return (
		<InputContainer>
			<PasswordInput
				id={props.fieldId}
				placeholder={props.text}
				value={password}
				onChange={handleOnChangePassword}
				{...props}
				type={shouldUsePasswordType ? 'password' : 'text'}
			/>
			<Label
				htmlFor={props.text}
			>
				{props.text}
			</Label>
			<ShowPasswordCheckboxContainer>
				<input
					id={`${props.fieldId}Shown`}
					type="checkbox"
					onChange={handleOnChangeShowPasswordButton}
				/>
				<EyeIcon
					htmlFor={`${props.fieldId}Shown`}
					active={isPasswordVisible}
				/>
			</ShowPasswordCheckboxContainer>
			<Error>
				{props.error}
			</Error>
		</InputContainer>
	);
};

const ShowPasswordCheckboxContainer = styled.div`
	position: absolute;
	top: 12px;
	right: 0;

	input {
		display: none;
	}
`;

function getEyeProjection({active}) {
	return active
		? eyeActiveProjection.src
		: eyeInactiveProjection.src;
}

const EyeIcon = styled.label`
	display: block;
	background-color: transparent;
	background-image: url(${getEyeProjection});
	width: 20px;
	height: 14px;
	border: none;
	margin-top: 14px;
	margin-right: 14px;
	cursor: pointer;
`;

const InputContainer = styled.div`
	position: relative;
	padding-top: 16px;
	margin-bottom: 5px;
`;

const PASSWORD_CHARACTER_SAFE_FONT = 'arial';
const PasswordInput = styled(CoreInput)`
	padding-right: 40px;

	&[type=password] {
		font-family: ${PASSWORD_CHARACTER_SAFE_FONT};
	}
`;

export default Password;
