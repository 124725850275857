import React, { useState } from 'react';
// --- external imports ------

import ResetBtnProjection from '~/assets/images/auth/btn-request.png';
import OffsetHeader from '~/authentication/elements/OffsetHeader';
import Input from '~/authentication/elements/Input';
import SubmitButton from '~/authentication/elements/SubmitButton';
import WhiteTextContainer from '~/authentication/elements/WhiteTextContainer';
import SmallOrangeForm from '~/authentication/elements/SmallOrangeForm';
import SmallOrangeFormFields from '~/authentication/elements/SmallOrangeFormFields';
import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import StyledRouterLink from '~/authentication/elements/StyledRouterLink';
import { validateEmail } from '~/authentication/lib/credentialValidation';
import { EMAIL_MAX_LENGTH } from '~/authentication/lib/constants-auth';
// --- internal imports ------

import resendRegistrationEmail from './apis/resendRegistrationEmail';
// --- local imports ------

function InvalidLink() {
	const [isProcessing, setIsProcessing] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [responseMessage, setResponseMessage] = useState(null);
	
	function handleOnSubmitForm(event) {
		event.preventDefault();	
		
		const email = event.target.email.value;

		const { isValid: isValidEmail, error: emailError } = validateEmail(email);
		setEmailError(emailError);
		if (!isValidEmail) {
			return;
		}

		setIsProcessing(true);

		resendRegistrationEmail(email)
			.then((response) => {
				const hasAlreadySetUpUser = response?.data?.hasAlreadySetUpUser;
				if (hasAlreadySetUpUser === true) {
					setResponseMessage('No email has been sent to you, as you\'re already \
						registered. If you can not log in, please attempt to use the Forgot \
						Password form.');
				}
				else if (hasAlreadySetUpUser === false) {
					setResponseMessage('We have sent an email to the specified account. Please \
						check your email. If you fail to find the email, please check your spam.');
				}
				else {
					setResponseMessage('An unexpected error has occured.');
				}

				setIsProcessing(true);
			})
			.catch((e) => {
				console.error(e);
				setResponseMessage('Failed to send the link. \
					Please try again later.');
			});
	}

	function handleOnEmailBlur(e) {
		const { error: emailError } = validateEmail(e.target.value);
		setEmailError(emailError);
	}

	function renderForm() {
		if (responseMessage !== null) {
			return (
				<WhiteTextContainer>
					{ responseMessage }
					<StyledRouterLink to="login">Back To Login</StyledRouterLink>
				</WhiteTextContainer>
			);
		}

		return (
			<>
				<WhiteTextContainer>
					Sorry.. this link has expired.<br />
					Please enter a registered email address and we will send
					you a new link to login.
				</WhiteTextContainer>
				<Input
					text="Email"
					fieldId="email"
					type="email"
					maxLength={EMAIL_MAX_LENGTH}
					onBlur={handleOnEmailBlur}
					error={emailError}
				/>
			</>
		);
	}

	return (
		<AuthenticationBackground
			minHeight={520}
		>
			<SmallOrangeForm
				onSubmit={handleOnSubmitForm}
			>
				<fieldset
					disabled={isProcessing}
				>
					<OffsetHeader useSmallText>
						Invalid Link
					</OffsetHeader>
					
					<SmallOrangeFormFields
						top={93}
						bottom={116}
						verticalAlign="start"
					>
						{ renderForm() }
					</SmallOrangeFormFields>

					{ responseMessage === null && (
						<SubmitButton
							disabled={isProcessing}
							projection={ResetBtnProjection}
						/>
					)}
				</fieldset>
			</SmallOrangeForm>
		</AuthenticationBackground>
	);
};

export default InvalidLink;
