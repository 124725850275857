import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// --- external imports ------

import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import WhiteLoadingPage from '~/authentication/elements/WhiteLoadingPage';
import RegisterPage from '~/authentication/RegisterPage/RegisterPage';
import { useUserContext } from '~/authentication/UserProvider';
// --- internal imports ------

function GuardedRoute(props) {
	const user = useUserContext();

	const {
		component: Component,
		children,
		path,
	} = props;

	return (
		<RenderRoute user={user} path={path} component={Component} children={children} />
	);
}

function RenderRoute(props) {

	const navigate = useNavigate();

	const {
		component: Component,
		children,
		user,
	} = props;


	if (user?.isCheckingInitialAuthData) {
		if (props.isUsingWhiteBackground) {
			return <WhiteLoadingPage />;
		}
		
		return <AuthenticationBackground />;
	}

	if (user?.tradersUserData) {
		if (user?.tradersUserData?.isRegistrationCompleted === false) {
			return <RegisterPage />;
		}
		
		return children || <Component />;
	}
	
	navigate("/login")
}

export default GuardedRoute;
