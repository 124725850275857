import styled from '@emotion/styled';
// --- external imports ------

import Header from './Header';
// --- local imports ------

const OffsetHeader = styled(Header)`
	position: absolute;
	margin: 0;
	left: 40px;
	top: ${({useSmallText}) => useSmallText ? -16 : -22}px;
	${({useSmallText}) => useSmallText && 'font-size: 40px;'}
`;

export default OffsetHeader;
