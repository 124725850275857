import config from '~/authentication/lib/config';
import { REQUEST_HEADERS } from '~/services/headers';
// --- internal imports ------

async function setupUserAccount(userToken, user) {
	try {
		if (!user.firstName) {
			console.error('\'firstName\' not provided in \'user\' object.');
			return false;
		}

		if (!user.lastName) {
			console.error('\'lastName\' not provided in \'user\' object.');
			return false;
		}

		if (!user.department) {
			console.error('\'department\' not provided in \'user\' object.');
			return false;
		}

		if (user.otherEmail === '') {
			delete user.otherEmail;
		}
		
		const baseUrl = config.userbaseServerBaseUrl;
		const response = await fetch(`${baseUrl}/api/user/`, {
			method: 'PATCH', 
			headers: {
				...REQUEST_HEADERS,
				'authorization': `Bearer ${userToken}`
			},
			body: JSON.stringify(user)
		});

		const result = await response.json();

		return result?.data?.user;
	}
	catch(e) {
		console.error(e);
		return null;
	}
}

export default setupUserAccount;
