import React from 'react';
import styled from '@emotion/styled';
// --- external imports ------

import Error from './Error';
import Label from './Label';
import CoreInput from './CoreInput';
// --- local imports ------

function Input(props) {
	return (
		<InputContainerStyled>
			<CoreInput
				id={props.fieldId}
				placeholder={props.text}
				{...props}
			/>
			<Label
				htmlFor={props.fieldId}
			>
				{props.text}
			</Label>
			<Error>
				{props.error}
			</Error>
		</InputContainerStyled>
	);
};

const InputContainerStyled = styled.div`
	position: relative;
	padding-top: 16px;
	margin-bottom: 5px;
`;

export default Input;
