import styled from '@emotion/styled';
// --- external imports ------

const linkStyle = `
	display: block;
	color: #72250c;
	font-size: 14px;
	font-family: wonderfull;
	margin: 28px 0;
`;

const StyledLink = styled.a`
	${linkStyle}
`;

export {
	linkStyle
};

export default StyledLink;
