import styled from '@emotion/styled';
// --- external imports ------

import SmallOrangeFormFields from './SmallOrangeFormFields';
// --- local imports ------

const LargeOrangeFormFields = styled(SmallOrangeFormFields)`
	left: 101px;
	right: 98px;
	top: 76px;
	bottom: 73px;
`;

export default LargeOrangeFormFields;
