import styled from '@emotion/styled';
// --- external imports ------

const SmallFormFields = styled.div`
	display: flex;
	place-content: center;
	flex-direction: column;
	position: absolute;
	left: 60px;
	right: 60px;
	top: ${({top}) => top ? (top + 'px') : '103px'};
	bottom: ${({bottom}) => bottom ? (bottom + 'px') : '59px'};

	> * {
		width: 100%;
	}
`;

export default SmallFormFields;
