import styled from '@emotion/styled';
// --- external imports ------

const TextContainer = styled.div`
	font-family: wonderfull;
	font-size: 14px;
	line-height: 27px;
	color: #72250c;
	margin-bottom: 20px;
`;

export default TextContainer;
