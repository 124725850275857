import React, { useState } from 'react';
// --- external imports ------

import StartBtnProjection from '~/assets/images/auth/btn-start.png';
import Input from '~/authentication/elements/Input';
import Dropdown from '~/authentication/elements/Dropdown';
import Password from '~/authentication/elements/Password';
import NameSection from '~/authentication/elements/NameSection';
import SubmitButton from '~/authentication/elements/SubmitButton';
import OffsetHeader from '~/authentication/elements/OffsetHeader';
import LargeOrangeForm from '~/authentication/elements/LargeOrangeForm';
import LargeOrangeFormFields from '~/authentication/elements/LargeOrangeFormFields';
import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import { useUserContext } from '~/authentication/UserProvider';
import {
	NAME_MAX_LENGTH,
	EMAIL_MAX_LENGTH,
	PASSWORD_MAX_LENGTH,
	DEPARTMENT_OPTIONS
} from '~/authentication/lib/constants-auth';
import {
	validateName,
	validateEmail,
	validatePassword,
	validateConfirmPassword
} from '~/authentication/lib/credentialValidation';
// --- internal imports ------

import { setupUserAccount } from './apis';
// --- local imports ------

function RegisterPage() {
	const user = useUserContext();
	const [isProcessing, setIsProcessing] = useState(false);
	const [firstNameError, setFirstNameError] = useState(null);
	const [lastNameError, setLastNameError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	const [optionalEmailError, setOptionalEmailError] = useState(null);

	async function handleOnSubmitForm(event) {
		event.preventDefault();
		
		const firstName = event.target?.firstName?.value;
		const lastName = event.target?.lastName?.value;
		const password = event.target?.password?.value;
		const confirmPassword = event.target?.confirmPassword?.value;
		const department = event.target?.department?.value;
		const optionalEmail = event.target?.optionalEmail?.value;
		
		const { isValid: isValidFirstName, error: firstNameError } = validateName(firstName);
		const { isValid: isValidLastName, error: lastNameError } = validateName(lastName);
		const { isValid: isValidPassword, error: passwordError } = validatePassword(password);
		const { isValid: isValidConfirmation, error: confirmationError } =
			validateConfirmPassword(password, confirmPassword);
		const { isValid: isValidEmail, error: emailError } = validateEmail(optionalEmail, false);
		
		setFirstNameError(firstNameError);
		setLastNameError(lastNameError);
		setPasswordError(passwordError);
		setConfirmPasswordError(confirmationError);
		setOptionalEmailError(emailError);

		if (!user || !isValidFirstName || !isValidLastName || !isValidPassword ||
			!isValidConfirmation || !isValidEmail) {
			return;
		}

		setIsProcessing(true);

		try {
			const firebaseToken = await user.firebaseUser.getIdToken();
			const updatedUser = await setupUserAccount(firebaseToken, {
				firstName,
				lastName,
				department,
				otherEmail: optionalEmail
			});
			user?.setTradersUserData?.(updatedUser);
			await user.firebaseUser.updatePassword(password);
		}
		catch(e) {
			console.error(e);
			setIsProcessing(false);
		}
	}

	function handleOnFirstNameBlur(e) {
		const { error: firstNameError } = validateName(e.target.value);
		setFirstNameError(firstNameError);
	}

	function handleOnLastNameBlur(e) {
		const { error: lastNameError } = validateName(e.target.value);
		setLastNameError(lastNameError);
	}

	function handleOnPasswordBlur(e) {
		const password = e.target.value;
		const confirmPassword = document.getElementById('confirmPassword').value;
		const { error: passwordError } = validatePassword(password);
		setPasswordError(passwordError);
		const { error: confirmPasswordError } = validateConfirmPassword(password, confirmPassword);
		setConfirmPasswordError(confirmPasswordError);
	}

	function handleOnConfirmPasswordBlur(e) {
		const password = document.getElementById('password').value;
		const confirmPassword = e.target.value;
		const { error: confirmPasswordError } = validateConfirmPassword(password, confirmPassword);
		setConfirmPasswordError(confirmPasswordError);
	}

	function handleOnOptionalEmailBlur(e) {
		const { error: emailError } = validateEmail(e.target.value, false);
		setOptionalEmailError(emailError);
	}

	return (
		<AuthenticationBackground
			minHeight={760}
		>
			<LargeOrangeForm
				onSubmit={handleOnSubmitForm}
			>
				<fieldset
					disabled={isProcessing}
				>
					<OffsetHeader>Registration</OffsetHeader>
					
					<LargeOrangeFormFields>
						<NameSection>
							<Input
								text="First Name"
								fieldId="firstName"
								type="text"
								maxLength={NAME_MAX_LENGTH}
								onBlur={handleOnFirstNameBlur}
								error={firstNameError}
							/>
							<Input
								text="Last Name"
								fieldId="lastName"
								type="text"
								maxLength={NAME_MAX_LENGTH}
								onBlur={handleOnLastNameBlur}
								error={lastNameError}
							/>
						</NameSection>
						<Input
							text="Email"
							fieldId="email"
							type="email"
							value={user?.firebaseUser?.email}
							onChange={() => {}}
							disabled
						/>
						<Password
							text="Password"
							fieldId="password"
							maxLength={PASSWORD_MAX_LENGTH}
							onBlur={handleOnPasswordBlur}
							error={passwordError}
						/>
						<Password
							text="Confirm Password"
							fieldId="confirmPassword"
							maxLength={PASSWORD_MAX_LENGTH}
							onBlur={handleOnConfirmPasswordBlur}
							error={confirmPasswordError}
						/>
						<Dropdown
							text="Department"
							fieldId="department"
							options={DEPARTMENT_OPTIONS}
							disabled={isProcessing}
						/>
						<Input
							text="Other Email (Optional)"
							fieldId="optionalEmail"
							type="email"
							maxLength={EMAIL_MAX_LENGTH}
							onBlur={handleOnOptionalEmailBlur}
							error={optionalEmailError}
						/>
					</LargeOrangeFormFields>

					{user && (
						<SubmitButton
							disabled={isProcessing}
							projection={StartBtnProjection}
						/>
					)}
				</fieldset>
			</LargeOrangeForm>
		</AuthenticationBackground>
	);
};

export default RegisterPage;
