import styled from '@emotion/styled';
// --- external imports ------

import RegisterBg from '~/assets/images/auth/large-orange-form-bg.png';
// --- internal imports ------

const LargeOrangeForm = styled.form`
	position: relative;
	width: ${RegisterBg.width}px;
	height: ${RegisterBg.height}px;
	background-image: url(${RegisterBg.src});
	z-index: 1;
`;

export default LargeOrangeForm;
