import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
// --- external imports ------

import dropdownDownProjection from '~/assets/images/auth/dropdown/input-field-down.png';
import dropdownUpProjection from '~/assets/images/auth/dropdown/input-field-up.png';
import arrowDownProjection from '~/assets/images/auth/dropdown/arrow-down.png';
import arrowUpProjection from '~/assets/images/auth/dropdown/arrow-up.png';
import menuItemFirstProjection from '~/assets/images/auth/dropdown/menu-first.png';
import menuItemFirstSelectedProjection from '~/assets/images/auth/dropdown/selection-first.png';
import menuItemMiddleProjection from '~/assets/images/auth/dropdown/menu-item.png';
import menuItemMiddleSelectedProjection from '~/assets/images/auth/dropdown/selection-item.png';
import menuItemLastProjection from '~/assets/images/auth/dropdown/menu-last.png';
import menuItemLastSelectedProjection from '~/assets/images/auth/dropdown/selection-last.png';
// --- internal imports ------

import Label from './Label';
// --- local imports ------

function Dropdown(props) {
	const [currentValue, setCurrentValue] = useState('');
	const [isOpened, setIsOpened] = useState(false);

	const firstValue = props?.options?.[0];
	useEffect(() => {
		setCurrentValue(firstValue);
	}, [props.options, firstValue]);

	useEffect(() => {
		if (props.disabled) {
			setIsOpened(false);
		}
	}, [props.disabled]);

	function toggleDropdown(e) {
		e.preventDefault();

		if (props?.disabled) {
			return;
		}

		const stateToSet = !isOpened;
		setIsOpened(stateToSet);
	}
	
	function selectItem(e) {
		e.preventDefault();
		setCurrentValue(e.target.innerText);
		setIsOpened(false);
	}

	return (
		<div>
			<input
				type="hidden"
				id={props.fieldId}
				value={currentValue}
			/>
			<Label>
				{props.text}
			</Label>
			<DropdownSelect
				onClick={toggleDropdown}
				isOpened={isOpened}
				disabled={props.disabled}
			>
				{ currentValue }
			</DropdownSelect>
			{isOpened && (
				<MenuContainer>
					{props.options?.map?.(
						(value) => (
							<MenuItem
								className={currentValue === value ? 'isSelected' : null}
								isSelected={currentValue === value}
								key={value}
								onClick={selectItem}
							>
								<div>{value}</div>
							</MenuItem>
						)
					)}
				</MenuContainer>
			)}
		</div>
	);
}

const MenuContainer = styled.div`
	position: absolute;
	width: 248px;
	margin-top: -14px;
	right: 0;
	z-index: 1;
`;

const MenuItem = styled.div`
	background-image: url(${menuItemMiddleProjection.src});
	height: 22px;
	color: ${({isSelected}) => isSelected ? '#f9c24d' : '#72250c'};
	font-family: wonderfull;
	font-size: 12px;
	cursor: pointer;
	padding: 0 15px 0 13px;

	&:hover {
		color: #f9c24d;
	}

	div {
		padding: 4px 15px;
		width: 220px;
		height: 22px;
	}

	&.isSelected div, &:hover div {
		background-image: url(${menuItemMiddleSelectedProjection.src});
	}
	
	&:first-of-type {
		background-image: url(${menuItemFirstProjection.src});
		height: 32px;
		padding: 6px 15px 1px 13px;

		div {
			padding: 7px 15px;
			height: 25px;
		}

		&.isSelected div, &:hover div {
			background-image: url(${menuItemFirstSelectedProjection.src});
		}
	}

	&:last-of-type {
		background-image: url(${menuItemLastProjection.src});
		height: 40px;
		padding: 1px 15px 16px 13px;

		div {
			height: 23px;
			padding: 7px 15px;
		}

		&.isSelected div, &:hover div {
			background-image: url(${menuItemLastSelectedProjection.src});
		}
	}
`;

function getSelectionBackground({ isOpened }) {
	return isOpened
		? dropdownUpProjection.src
		: dropdownDownProjection.src;
}

function getArrowBackground({ isOpened }) {
	return isOpened
		? arrowUpProjection.src
		: arrowDownProjection.src;
}

const DropdownSelect = styled.button`
	position: relative;
	cursor: ${({disabled}) => disabled ? 'not-allowed;' : 'pointer'};
	font-family: wonderfull;
	font-size: 16px;
	color: #72250c;
	text-align: left;

	outline: none;
	border: none;
	padding: 4px 12px 0px 12px;
	padding-bottom: ${({isOpened}) => isOpened ? '0' : '11px'};
	background-color: transparent;
	background-image: url(${getSelectionBackground});
	width: 320px;
	height: ${({isOpened}) => isOpened ? '39px' : '50px'};
	margin-bottom: ${({isOpened}) => isOpened ? '21px' : '10px'};

	&::after {
		position: absolute;
		content: '';
		width: 18px;
		height: 12px;
		right: 34px;
		top: ${({isOpened}) => isOpened ? '34px' : '32px'};
		background-color: transparent;
		background-size: 100% 100%;
		background-image: url(${getArrowBackground});
	}
`;

export default Dropdown;
