import React, { useEffect, useState } from 'react';
// --- external imports ------

import StatusScreen from '~/authentication/StatusScreen';
// --- internal imports ------

import EmailLoginRedirector from './EmailLoginRedirector';
import ResetPasswordPage from './ResetPasswordPage';
// --- local imports ------

function ActionPage() {
	const [actionMode, setActionMode] = useState('');
	const [actionOobCode, setActionOobCode] = useState('');

	function extractActionFromUrl() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		setActionMode(urlParams.get('mode'));
		setActionOobCode(urlParams.get('oobCode'));
	}
	
	useEffect(extractActionFromUrl, []);
	
	switch (actionMode) {
	case 'signIn':
		return <EmailLoginRedirector />;
	case 'resetPassword':
		return <ResetPasswordPage actionOobCode={actionOobCode} />;
	default:
		return (
			<StatusScreen
				header="Failure"
				message="Invalid action type. Please make sure you
					used a link emailed to you by us."
			/>
		);
	}
};

export default ActionPage;
