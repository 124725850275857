import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
// --- external imports ------

import { linkStyle } from './StyledLink';
// --- internal imports ------

const StyledRouterLink = styled(Link)`
	${linkStyle}
`;

export default StyledRouterLink;
