import styled from '@emotion/styled';
// --- external imports ------

const CoreInput = styled.input`
	display: block;
	width: 100%;
	height: 34px;
	border: none;

	font-family: wonderfull;
	font-size: 16px;
	color: #72250c;

	line-height: 2.5;
	padding: 4px 10px 2px 12px;
	border-radius: 8px;
	box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
	background-color: rgba(0, 0, 0, 0.1);
	border: 1px solid transparent;
	outline: none;

	transition: 0.5s ease background-color;

	& ~ label {
		position: absolute;
		top: 0px;
		transition: 0.5s ease color, 0.5s ease transform;
	}

	::placeholder {
		color: #72250ccc;
		transition: 0.5s ease color;
		overflow: visible;
	}

	&:not(:focus):placeholder-shown::placeholder {
		color: transparent;
	}

	&:not(:focus):placeholder-shown ~ label {
		transform: translate(14px, 26px) scale(1.4);
		color: #72250ccc;
	}

	&:focus {
		border-color: rgb(255, 239, 134);
	}

	&:disabled {
		cursor: not-allowed;
		background-color: rgba(32, 32, 32, 0.4);
	}
`;

export default CoreInput;
