import React from 'react';
import styled from '@emotion/styled';
// --- external imports ------

import spinnerProjection from '~/assets/images/auth/loading-spinner.png';
// --- internal imports ------

function SubmitButton(props) {
	return (
		<SubmitButtonContainer
			{...props}
		>
			<img src={props.projection.src} alt="Play" />
		</SubmitButtonContainer>
	);
}

const SubmitButtonContainer = styled.button`
	position: absolute;
	left: 50%;
	bottom: -8px;
	transform: translateX(-50%);
	background: none;
	outline: none;
	border: none;
	cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
	
	&::after {
		content: '';
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(${spinnerProjection});
		position: absolute;
		left: 50%;
		width: 24px;
		height: 24px;
		margin-left: -12px;
		margin-top: -12px;
		transition: 0.5s ease opacity top;
		animation: rotation 1s infinite linear;
		opacity: ${({disabled}) => disabled ? 1 : 0};
		top: ${({disabled}) => disabled ? '-5px' : '50%'};
	}

	img {
		opacity: ${({disabled}) => disabled ? 0 : 1};
		transition: 0.5s ease opacity;
	}
`;

export default SubmitButton;
