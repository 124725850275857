import React from 'react';
// --- external imports ------

import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import SmallOrangeFormFields from '~/authentication/elements/SmallOrangeFormFields';
import SmallOrangeForm from '~/authentication/elements/SmallOrangeForm';
import WhiteTextContainer from '~/authentication/elements/WhiteTextContainer';
import OffsetHeader from '~/authentication/elements/OffsetHeader';
// --- internal imports ------

function StatusScreen({ header, message }) {
	return (
		<AuthenticationBackground
			minHeight={520}
		>
			<SmallOrangeForm>
				<OffsetHeader>{ header }</OffsetHeader>

				<SmallOrangeFormFields>
					<WhiteTextContainer>
						{ message }
					</WhiteTextContainer>
				</SmallOrangeFormFields>
			</SmallOrangeForm>
		</AuthenticationBackground>
	);
}

export default StatusScreen;
