import ValidationResult from './ValidationResult';
import VALIDATION_ERRORS from './errors-validation';
import {
	NAME_MIN_LENGTH,
	NAME_MAX_LENGTH,
	PASSWORD_MIN_LENGTH
} from './constants-auth';
import {
	hasLowerCase,
	hasNumber,
	hasUpperCase,
	isEmail
} from './stringHelpers';
// --- local imports ------

const nameRegex = /[^\s\d±!@£\?$%^&*_+§¡€#¢§¶•ªº«'"\\/{}()<>?:;|=\-.,]/;
export function validateName(name) {
	if (name.length < NAME_MIN_LENGTH) {
		return new ValidationResult(VALIDATION_ERRORS.NAMES.TOO_SHORT);
	}
	else if (name.length > NAME_MAX_LENGTH) {
		return new ValidationResult(VALIDATION_ERRORS.NAMES.TOO_LONG);
	}
	else if (!name.match(nameRegex)) {
		return new ValidationResult(VALIDATION_ERRORS.NAMES.MUST_CONTAIN_ALPHA);
	}

	return new ValidationResult();
}

export function validatePassword(password) {
	if (password.length < PASSWORD_MIN_LENGTH) {
		return new ValidationResult(VALIDATION_ERRORS.PASSWORDS.TOO_SHORT);
	}
	else if (!hasNumber(password)) {
		return new ValidationResult(VALIDATION_ERRORS.PASSWORDS.MUST_CONTAIN_NUMBER);
	}
	else if (!hasLowerCase(password)) {
		return new ValidationResult(VALIDATION_ERRORS.PASSWORDS.MUST_CONTAIN_LOWERCASE);
	}
	else if (!hasUpperCase(password)) {
		return new ValidationResult(VALIDATION_ERRORS.PASSWORDS.MUST_CONTAIN_UPPERCASE);
	}

	return new ValidationResult();
}

export function validateConfirmPassword(password, passwordConfirm) {
	if (password !== passwordConfirm) {
		return new ValidationResult(VALIDATION_ERRORS.PASSWORDS.CONFIRM_NO_MATCH);
	}

	return new ValidationResult();
}

export function validateRequiredEmail(email) {
	if (email.length === 0) {
		return new ValidationResult(VALIDATION_ERRORS.EMAIL.REQUIRED);
	}
	else if (!isEmail(email)) {
		return new ValidationResult(VALIDATION_ERRORS.EMAIL.INVALID);
	}

	return new ValidationResult();
}

export function validateOptionalEmail(email) {
	if (email.length > 0 && !isEmail(email)) {
		return new ValidationResult(VALIDATION_ERRORS.EMAIL.OPTIONAL_INVALID);
	}

	return new ValidationResult();
}

export function validateEmail(email, isRequired = true) {
	if (isRequired) {
		return validateRequiredEmail(email);
	}
	else {
		return validateOptionalEmail(email);
	}
}
