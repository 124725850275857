import React from 'react';
import styled from '@emotion/styled';
// --- external imports ------

import checkProjection from '~/assets/images/auth/check.png';
// --- internal imports ------

function Checkbox(props) {
	return (
		<LabelStyled
			htmlFor={props.text}
		>
			<CheckboxStyled
				id={props.text}
				type="checkbox"
				onChange={props.onChange}
				checked={props.checked}
			/>
			<Checkmark />
			<LabelTextStyled>
				{props.text}
			</LabelTextStyled>
		</LabelStyled>
	);
};

const LabelStyled = styled.label`
	display: block;
	cursor: pointer;
	user-select: none;
`;

const LabelTextStyled = styled.span`
	vertical-align: middle;
	font-family: wonderfull;
	font-size: 14px;
	color: #72250c;
`;

const CheckboxStyled = styled.input`
	display: none;

	&:checked ~ div {
		background-image: url(${checkProjection.src});
	}
`;

const Checkmark = styled.div`
	vertical-align: middle;
	margin-right: 12px;
	display: inline-block;
	background: green;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.25);
	background-color: rgba(0, 0, 0, 0.1);
	background-position: center;
	background-size: 60%;
	background-repeat: no-repeat;
`;

export default Checkbox;
