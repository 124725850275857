import styled from '@emotion/styled';
// --- external imports ------

import bgProjection from '~/assets/images/auth/bg.jpg';
import zigzag from '~/assets/images/auth/zigzag.png';
// --- internal imports ------

const AuthenticationBackground = styled.div`
	display: flex;
	place-content: center;
	place-items: center;
	position: absolute;
	left: 0;
	top: 0;
	background: #cdf;
	background-image: url(${bgProjection.src});
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 100vh;
	min-height: ${({minHeight}) => minHeight || 0}px;

	&::before {
		content: ' ';
		position: absolute;
		top: 3vh;
		height: 5vh;
		width: 100%;
		background-size: auto 100%;
		background-position: center;
		background-image: url(${zigzag.src});
	}

	&::after {
		content: ' ';
		position: absolute;
		bottom: 3vh;
		height: 5vh;
		width: 100%;
		transform: rotate(180deg);
		background-size: auto 100%;
		background-position: center;
		background-image: url(${zigzag.src});
	}
`;

export default AuthenticationBackground;
