import React, { useState } from 'react';
// --- external imports ------

import PlayBtnProjection from '~/assets/images/auth/btn-play.png';
import firebase, { persistence } from '~/authentication/lib/firebase';
import Input from '~/authentication/elements/Input';
import Checkbox from '~/authentication/elements/Checkbox';
import Password from '~/authentication/elements/Password';
import OffsetHeader from '~/authentication/elements/OffsetHeader';
import SubmitButton from '~/authentication/elements/SubmitButton';
import SmallOrangeForm from '~/authentication/elements/SmallOrangeForm';
import StyledRouterLink from '~/authentication/elements/StyledRouterLink';
import SmallOrangeFormFields from '~/authentication/elements/SmallOrangeFormFields';
import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import { validatePassword, validateEmail } from '~/authentication/lib/credentialValidation';
import { ERROR_CODES, handleBaseError } from '~/authentication/lib/errors';
import { EMAIL_MAX_LENGTH, PASSWORD_MAX_LENGTH } from '~/authentication/lib/constants-auth';
// --- internal imports ------

function LoginPage() {
	const [isProcessing, setIsProcessing] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [isSessionSaved, setIsSessionSaved] = useState(false);
	
	function handleFirebaseError(error) {
		setIsProcessing(false);
		const code = error?.code;
		
		switch(code) {
		case ERROR_CODES.FIREBASE.LOGIN.INVALID_EMAIL:
			setEmailError('Please enter a valid email!');
			break;
		case ERROR_CODES.FIREBASE.LOGIN.WRONG_PASSWORD:
			setPasswordError('Incorrect Password!');
			break;
		case ERROR_CODES.FIREBASE.LOGIN.USER_DISABLED:
			setEmailError('This user has been disabled! Please contact your HR manager!');
			break;
		case ERROR_CODES.FIREBASE.LOGIN.USER_NOT_FOUND:
			setEmailError('The user associated with this email was not found!');
			break;
		default:
			const errorMessage = handleBaseError(error);
			setEmailError(errorMessage);
		}
	}

	function handleOnSubmitForm(event) {
		event.preventDefault();
		
		const email = event.target.email.value;
		const password = event.target.password.value;

		const { isValid: isValidEmail, error: emailError } = validateEmail(email);
		setEmailError(emailError);
		if (!isValidEmail) {
			return;
		}

		const { isValid: isValidPassword, error: passwordError } = validatePassword(password);
		setPasswordError(passwordError);
		if (!isValidPassword) {
			return;
		}

		setIsProcessing(true);
		
		if (isSessionSaved) {
			firebase.setPersistence(persistence.LOCAL);
		}
		else {
			firebase.setPersistence(persistence.NONE);
		}

		firebase
			.signInWithEmailAndPassword(email, password)
			.catch(handleFirebaseError);
	}

	function handleOnEmailBlur(e) {
		const { error: emailError } = validateEmail(e.target.value);
		setEmailError(emailError);
	}

	function handleOnPasswordBlur(e) {
		const { error: passwordError } = validatePassword(e.target.value);
		setPasswordError(passwordError);
	}

	function handleOnSessionSavedChange(e) {
		setIsSessionSaved(e.target.checked);
	}
	
	return (
		<AuthenticationBackground
			minHeight={520}
		>
			<SmallOrangeForm
				onSubmit={handleOnSubmitForm}
			>
				<fieldset
					disabled={isProcessing}
				>
					<OffsetHeader>Login</OffsetHeader>

					<SmallOrangeFormFields>
						<Input
							text="Email"
							fieldId="email"
							type="email"
							maxLength={EMAIL_MAX_LENGTH}
							error={emailError}
							onBlur={handleOnEmailBlur}
							data-testid="login-email-input"
						/>
						<Password
							text="Password"
							fieldId="password"
							maxLength={PASSWORD_MAX_LENGTH}
							error={passwordError}
							onBlur={handleOnPasswordBlur}
							data-testid="login-password-input"
						/>
						<Checkbox
							text="Remember me"
							onChange={handleOnSessionSavedChange}
							checked={isSessionSaved}
						/>
						<StyledRouterLink to="/forgot">Forgot Password</StyledRouterLink>
					</SmallOrangeFormFields>

					<SubmitButton
						disabled={isProcessing}
						projection={PlayBtnProjection}
						data-testid="login-submit"
					/>
				</fieldset>
			</SmallOrangeForm>
		</AuthenticationBackground>
	);
};

export default LoginPage;
