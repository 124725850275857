import launchGame from '~/GameLauncher';
// --- internal imports ------

function GameMounter() {
	launchGame();

	return '';
};

export default GameMounter;
