export const FIREBASE_ERROR_CODES =  {
	GENERAL: {
		APP_DELETED: 'auth/app-deleted',
		APP_NOT_AUTHORIZED: 'auth/app-not-authorized',
		ARGUMENT_ERROR: 'auth/argument-error',
		INVALID_API_KEY: 'auth/invalid-api-key',
		INVALID_USER_TOKEN: 'auth/invalid-user-token',
		INVALID_TENANT_ID: 'auth/invalid-tenant-id',
		NETWORK_REQUEST_FAILED: 'auth/network-request-failed',
		OPERATION_NOT_ALLOWED: 'auth/operation-not-allowed',
		REQUIRES_RECENT_LOGIN: 'auth/requires-recent-login',
		TOO_MANY_REQUESTS: 'auth/too-many-requests',
		UNAUTHORIZED_DOMAIN: 'auth/unauthorized-domain',
		USER_DISABLED: 'auth/user-disabled',
		USER_TOKEN_EXPIRED: 'auth/user-token-expired',
		WEB_STORAGE_UNSUPPORTED: 'auth/web-storage-unsupported'
	},
	LOGIN: {
		INVALID_EMAIL: 'auth/invalid-email',
		WRONG_PASSWORD: 'auth/wrong-password',
		USER_DISABLED: 'auth/user-disabled',
		USER_NOT_FOUND: 'auth/user-not-found'
	},
	FORGOT: {
		INVALID_EMAIL: 'auth/invalid-email',
		MISSING_ANDROID_PKG_NAME: 'auth/missing-android-pkg-name',
		MISSING_CONTINUE_URI: 'auth/missing-continue-uri',
		MISSING_IOS_BUNDLE_ID: 'auth/missing-ios-bundle-id',
		INVALID_CONTINUE_URI: 'auth/invalid-continue-uri',
		UNAUTHORIZED_CONTINUE_URI: 'auth/unauthorized-continue-uri',
		USER_NOT_FOUND: 'auth/user-not-found'
	},
	RESET: {
		EXPIRED_ACTION_CODE: 'auth/expired-action-code',
		INVALID_ACTION_CODE: 'auth/invalid-action-code',
		USER_DISABLED: 'auth/user-disabled',
		USER_NOT_FOUND: 'auth/user-not-found',
		WEAK_PASSWORD: 'auth/weak-password'
	}
};
