export function hasUpperCase(myString) {
	return /[A-Z]/.test(myString);
}

export function hasLowerCase(myString) {
	return /[a-z]/.test(myString);
}

export function hasNumber(myString) {
	return /\d/.test(myString);
}

export function isEmail(string) {
	const exp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
	return exp.test(string);
}
