import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// --- external imports ------

import ResetBtnProjection from '~/assets/images/auth/btn-reset.png';
import firebase from '~/authentication/lib/firebase';
import Password from '~/authentication/elements/Password';
import OffsetHeader from '~/authentication/elements/OffsetHeader';
import SubmitButton from '~/authentication/elements/SubmitButton';
import TextContainer from '~/authentication/elements/TextContainer';
import StyledRouterLink from '~/authentication/elements/StyledRouterLink';
import SmallOrangeForm from '~/authentication/elements/SmallOrangeForm';
import SmallOrangeFormFields from '~/authentication/elements/SmallOrangeFormFields';
import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import { validatePassword, validateConfirmPassword } from '~/authentication/lib/credentialValidation';
import { ERROR_CODES, handleBaseError } from '~/authentication/lib/errors';
import { PASSWORD_MAX_LENGTH } from '~/authentication/lib/constants-auth';
// --- internal imports ------

function ResetPasswordPage(props) {
	const navigate = useNavigate();
	const [isProcessing, setIsProcessing] = useState(false);
	const [generalStatus, setGeneralStatus] = useState('Checking verification code...');
	const [passwordError, setPasswordError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);
	
	useEffect(checkCode, [props.actionOobCode]);

	function handleFirebaseError(error) {
		setIsProcessing(false);

		switch(error?.code) {
		case ERROR_CODES.FIREBASE.RESET.EXPIRED_ACTION_CODE:
			setGeneralStatus('The action code has expired. \
			Please try the forgot password form again.');
			break;
		case ERROR_CODES.FIREBASE.RESET.INVALID_ACTION_CODE:
			setGeneralStatus('The action code is invalid. \
			Please try the forgot password form again.');
			break;
		case ERROR_CODES.FIREBASE.RESET.USER_DISABLED:
			setGeneralStatus('This user is disabled. Please contact HR');
			break;
		case ERROR_CODES.FIREBASE.RESET.USER_NOT_FOUND:
			setGeneralStatus('This user was not found. Please contact HR');
			break;
		default:
			const errorMessage = handleBaseError(error);
			setPasswordError(errorMessage);
		};
	}

	function checkCode() {
		if (props.actionOobCode) {
			firebase
				.verifyPasswordResetCode(props.actionOobCode)
				.then(function() {
					setGeneralStatus(null);
				})
				.catch(handleFirebaseError);
		}
		else {
			setGeneralStatus('No status code has been provided.');
		}
	}

	function handleOnSubmitForm(event) {
		event.preventDefault();	
		
		const password = event.target.password.value;
		const confirmPassword = event.target.confirmPassword.value;

		const { isValid: isValidPassword, error: passwordError } = validatePassword(password);
		setPasswordError(passwordError);
		if (!isValidPassword) {
			return;
		}

		const { isValid: isValidConfirmPassword, error: confirmPasswordError } =
			validateConfirmPassword(password, confirmPassword);
		setConfirmPasswordError(confirmPasswordError);
		if (!isValidConfirmPassword) {
			return;
		}

		setIsProcessing(true);

		firebase
			.confirmPasswordReset(props.actionOobCode, password)
			.then(function() {
				navigate('/login');
			})
			.catch(handleFirebaseError);
	}

	function handleOnPasswordBlur(e) {
		const password = e.target.value;
		const confirmPassword = document.getElementById('confirmPassword').value;
		const { error: passwordError } = validatePassword(password);
		setPasswordError(passwordError);
		const { error: confirmPasswordError } = validateConfirmPassword(password, confirmPassword);
		setConfirmPasswordError(confirmPasswordError);
	}

	function handleOnConfirmPasswordBlur(e) {
		const password = document.getElementById('password').value;
		const confirmPassword = e.target.value;
		const { error: confirmPasswordError } = validateConfirmPassword(password, confirmPassword);
		setConfirmPasswordError(confirmPasswordError);
	}

	function renderForm() {
		if (generalStatus) {
			return (
				<TextContainer>
					{ generalStatus }
					<br />
					<StyledRouterLink to="/login">
						Go to login page
					</StyledRouterLink>
				</TextContainer>
			);
		}

		return (
			<>
				<Password
					text="Password"
					fieldId="password"
					maxLength={PASSWORD_MAX_LENGTH}
					onBlur={handleOnPasswordBlur}
					error={passwordError}
				/>
				<Password
					text="Confirm Password"
					fieldId="confirmPassword"
					maxLength={PASSWORD_MAX_LENGTH}
					onBlur={handleOnConfirmPasswordBlur}
					error={confirmPasswordError}
				/>
			</>
		);
	}

	return (
		<AuthenticationBackground
			minHeight={520}
		>
			<SmallOrangeForm
				onSubmit={handleOnSubmitForm}
			>
				<fieldset
					disabled={isProcessing}
				>
					<OffsetHeader useSmallText>
						Password Reset
					</OffsetHeader>
					
					<SmallOrangeFormFields>
						{renderForm()}
					</SmallOrangeFormFields>

					{ generalStatus === null && (
						<SubmitButton
							disabled={isProcessing}
							projection={ResetBtnProjection}
						/>
					)}
				</fieldset>
			</SmallOrangeForm>
		</AuthenticationBackground>
	);
};

export default ResetPasswordPage;
