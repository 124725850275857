import React from 'react';
import ReactDOM from 'react-dom/client';
// --- external imports -----

import FeaturesFlags from '~/lib/featuresFlags';
// --- internal imports -----

import { root } from '../index'
// --- parent imports -----

import assetsLoader from './assets';
// --- local imports -----

const rootElement = document.getElementById('root');
const splashscreenElement = document.getElementById('splashscreen-root');
const splashscreenPlaceholderElement = document.getElementById('splashscreen-placeholder');

const splashScreenRoot = ReactDOM.createRoot(splashscreenElement);

function startLoadingAssets() {
	rootElement.style.display = 'none';
	splashscreenPlaceholderElement.style.display = 'block';
	splashscreenElement.style.display = 'block';
	assetsLoader((d) => {
		console.log(
			'[SplashScreen]',
			d.category,
			d.groupName,
			d.status,
			`(${d.completed})`,
			`${d.progress}%`,
		);
	});
}

function mountGame() {
	import('../Game')
		.then(({ default: TradersGame }) => {
			root.render(<TradersGame />)
		});
}


export default function launchGame() {
	const isGameLoaderFeatureEnabled = FeaturesFlags['gameLoader'];

	// in case of flag doesn't exits consider it as true
	if (isGameLoaderFeatureEnabled === false) {
		return mountGame();
	}

	startLoadingAssets();

	import('./GameLoader')
		.then(({ default: GameLoader }) => {
			splashScreenRoot.render(<GameLoader onLoadComplete={mountGame} />)
		});
}

