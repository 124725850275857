import styled from '@emotion/styled';
// --- external imports ------

const Label = styled.label`
	font-family: wonderfull;
	font-size: 12px;
	color: #ffef86;
	pointer-events: none;
	transform-origin: left center;
`;

export default Label;
