function getFeatureFlagsFromEnv() {
	const featureFlagsEnvVar = process.env.REACT_APP_FEATURE_FLAGS;
    
	if (!featureFlagsEnvVar || featureFlagsEnvVar.trim().length === 0) {
		return {};
	}

	try {
		return JSON.parse(featureFlagsEnvVar);
	}
	catch(e) {
		console.warn(`
            failed to parse feature flags:${featureFlagsEnvVar} 
            returning empty obj`
		, e);
		return { };
	}
}

export default getFeatureFlagsFromEnv();
