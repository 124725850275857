import React from 'react';
import {
	Route,
	Navigate,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements
} from 'react-router-dom';
// ---- external imports ------

import { AuthRoute, GuardedRoute } from '~/authentication/routes';
import GameMounter from '~/GameLauncher/GameMounter';
import {
	LoginPage,
	EmailActionPage,
	ForgotPasswordPage,
} from '~/authentication';
// ---- internal imports ------

import './authFonts.css';
// ---- local imports ------

function App() {
	const router = createBrowserRouter(
		createRoutesFromElements(
			
			<Route>
				<Route path="emailAction" element={<AuthRoute path="/emailAction" component={EmailActionPage} ></AuthRoute>} />
				<Route path="forgot" element={<AuthRoute path="/forgot" isUsingWhiteBackground component={ForgotPasswordPage} ></AuthRoute>} />
				<Route path="login" element={<AuthRoute path="/login" isUsingWhiteBackground component={LoginPage} ></AuthRoute> } />
				<Route path="/">
					<Route path='game/*' element={<GuardedRoute path="/game" component={GameMounter} />} />
					<Route path="/" element={<Navigate to="/login" />} />
				</Route>
				<Route path='*' element={<Navigate to="/login" />} />
			</Route>
		)
	);

	return (
		<RouterProvider router={router} />
	);
}

export default App;
