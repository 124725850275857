import importAll from 'import-all.macro';

import { deduceLoaders, relPreload } from '../helper';



const imagesLoaders = deduceLoaders(
	importAll.deferred('./images/*.{png,jpg}'),
	'images',
	{ preloader: relPreload.bind({ asType: 'image' }), }
);


export default function assetsLoader(callback) {
	return new Promise(async (resolve) => {

		const loaders = [
			...Object.values(imagesLoaders),
		];

		for (const loader of loaders) {
			await loader?.(callback);
		}

		resolve();
	});
}
