import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// --- external imports ------

import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import firebase from '~/authentication/lib/firebase';
import InvalidLink from '~/authentication/InvalidLink';
// --- internal imports ------

function EmailLoginRedirector() {
	const history = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	useEffect(handleOnMount, [history]);

	function handleOnMount() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		const email = urlParams.get('email');
		if (email === null) {
			console.error('No email provided with link!');
			setIsLoading(false);
			return;
		}

		firebase
			.signInWithEmailLink(email, window.location.href)
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	}

	if (isLoading) {
		return <AuthenticationBackground />;
	}

	return <InvalidLink />;
};

export default EmailLoginRedirector;
