import React, { useState } from 'react';
// --- external imports ------

import firebase from '~/authentication/lib/firebase';
import LinkBtnProjection from '~/assets/images/auth/btn-link.png';
import Input from '~/authentication/elements/Input';
import StyledRouterLink from '~/authentication/elements/StyledRouterLink';
import OffsetHeader from '~/authentication/elements/OffsetHeader';
import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import SubmitButton from '~/authentication/elements/SubmitButton';
import WhiteTextContainer from '~/authentication/elements/WhiteTextContainer';
import SmallOrangeForm from '~/authentication/elements/SmallOrangeForm';
import SmallOrangeFormFields from '~/authentication/elements/SmallOrangeFormFields';
import { validateEmail } from '~/authentication/lib/credentialValidation';
import { ERROR_CODES, handleBaseError } from '~/authentication/lib/errors';
import { EMAIL_MAX_LENGTH } from '~/authentication/lib/constants-auth';
// --- internal imports ------

const EMAIL_ACTION_CODE = {
    url: process.env.REACT_APP_FIREBASE_EMAIL_CONTINUE_URL || '',
    continueUrl: process.env.REACT_APP_FIREBASE_EMAIL_CONTINUE_URL || '',
};

function ForgotPasswordPage() {
	const [isProcessing, setIsProcessing] = useState(false);
	const [generalStatus, setGeneralStatus] = useState(null);
	const [emailError, setEmailError] = useState(null);

	function handleFirebaseError(error) {
		setIsProcessing(false);
		const code = error?.code;
		
		switch(code) {
		case ERROR_CODES.FIREBASE.FORGOT.INVALID_EMAIL:
			setEmailError('Please enter a valid email!');
			break;
		case ERROR_CODES.FIREBASE.FORGOT.USER_NOT_FOUND:
			setEmailError('The user associated with this email was not found!');
			break;
		default:
			const errorMessage = handleBaseError(error);
			setEmailError(errorMessage);
		}
	}

	function handleOnSubmitForm(event) {
		event.preventDefault();	
		
		const email = event.target.email.value;

		const { isValid: isValidEmail, error: emailError } = validateEmail(email);
		setEmailError(emailError);
		if (!isValidEmail) {
			return;
		}
		
		setIsProcessing(true);

		firebase
			.sendPasswordResetEmail(email, EMAIL_ACTION_CODE)
			.then(function() {
				setGeneralStatus('Email correctly sent. Please check your inbox.');
				setIsProcessing(false);
			}).catch(function(error) {
				handleFirebaseError(error);
			});
	}

	function handleOnEmailBlur(e) {
		const { error: emailError } = validateEmail(e.target.value);
		setEmailError(emailError);
	}

	function renderForm() {
		if (generalStatus) {
			return (
				<WhiteTextContainer>
					{ generalStatus }
					<br />
				</WhiteTextContainer>
			);
		}
		
		return (
			<>
				<WhiteTextContainer>
					A link will be sent to your registration
					email to help reset your password.
				</WhiteTextContainer>
				<Input
					text="Email"
					fieldId="email"
					type="email"
					maxLength={EMAIL_MAX_LENGTH}
					onBlur={handleOnEmailBlur}
					error={emailError}
				/>
			</>
		);
	}

	return (
		<AuthenticationBackground
			minHeight={520}
		>
			<SmallOrangeForm
				onSubmit={handleOnSubmitForm}
			>
				<fieldset
					disabled={isProcessing}
				>
					<OffsetHeader useSmallText>
						Forgot Password
					</OffsetHeader>
					
					<SmallOrangeFormFields>
						{ renderForm() }
						<StyledRouterLink to="login">Back To Login</StyledRouterLink>
					</SmallOrangeFormFields>

					{!generalStatus && (
						<SubmitButton
							disabled={isProcessing}
							projection={LinkBtnProjection}
						/>
					)}
				</fieldset>
			</SmallOrangeForm>
		</AuthenticationBackground>
	);
};

export default ForgotPasswordPage;
