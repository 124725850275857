import styled from '@emotion/styled';
// --- external imports ------

const Error = styled.div`
	font-family: Nunito, Calibri, Sans-Serif;
	font-size: 10px;
	color: #fffbdd;
	margin-left: 12px;
	margin-top: 4px;
	min-height: 2ch;
`;

export default Error;
