import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
// --- external imports ------

import firebase from '~/authentication/lib/firebase';
// --- internal imports ------

import { getTradersUserData } from './apis';
// --- local imports ------

const UserContext = createContext({user: undefined});

function UserProvider(props) {
	const [firebaseUser, setFirebaseUser] = useState(undefined);
	const [tradersUserData, setTradersUserData] = useState(undefined);
	const [isCheckingInitialAuthData, setIsCheckingInitialAuthData] = useState(true);
	const [tradersToken, setTradersToken] = useState(localStorage.getItem('tradersToken') ?? '');

	const fetchTradersUserData = useCallback(async (user = firebaseUser) => {
		if (tradersToken) {
			try {
			  setIsCheckingInitialAuthData(true);
			  const newTradersUserData = await getTradersUserData(tradersToken);
			  setTradersUserData(newTradersUserData);
			} catch (error) {
			  console.error('Error fetching traders user data:', error);
			  // Handle error (e.g., invalid token)
			  setTradersUserData(null);
			  localStorage.removeItem('tradersToken');
			  setTradersToken('');
			} finally {
			  setIsCheckingInitialAuthData(false);
			}
		  } else {
			setTradersUserData(null);
			setIsCheckingInitialAuthData(false);
		  }
	}, [tradersToken]);

	const handleOnAuthStateChanged = useCallback(async (newUser) => {
		if (newUser) {
			setTradersUserData(undefined);
			setFirebaseUser(newUser);
			const firebaseToken = await newUser.getIdToken();
			const response = await fetch(`${process.env.REACT_APP_USERBASE_SERVER_BASE_URL}/auth/login-player`, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${firebaseToken}`,
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': `*`
				},
			});

			const { token, user } = await response.json();
			setTradersUserData(user);
			localStorage.setItem('tradersToken', token);	//	i think we can encrypt this with some sort of env variable?
			setTradersToken(token);
		}
		else {
			if (tradersToken) {
				return;
			}
			setFirebaseUser(null);
			setTradersUserData(null);
			setIsCheckingInitialAuthData(false);
		}
	}, [fetchTradersUserData, tradersToken]);

	useEffect(() => {
		fetchTradersUserData();
	}, [tradersToken, fetchTradersUserData]);

	useEffect(() => {
		firebase.onAuthStateChanged(handleOnAuthStateChanged);
	}, [handleOnAuthStateChanged]);
	
	return (
		<UserContext.Provider
			value={{
				firebaseUser,
				tradersUserData,
				isCheckingInitialAuthData,
				setTradersUserData
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
}

export function useUserContext() {
	return useContext(UserContext);
}

export default UserProvider;

export { UserContext };
