import React from 'react';
import { useNavigate } from 'react-router-dom';
// --- external imports ------

import AuthenticationBackground from '~/authentication/elements/AuthenticationBackground';
import WhiteLoadingPage from '~/authentication/elements/WhiteLoadingPage';
import { useUserContext } from '~/authentication/UserProvider';
// --- internal imports ------

function AuthRoute(props) {
	const user = useUserContext();

	const {
		component: Component,
		children,
		path,
		isUsingWhiteBackground,
	} = props;

	return (
		<RenderRoute children={children} Component={Component} path ={path} isUsingWhiteBackground={isUsingWhiteBackground} user={user}/>
	);
}

function RenderRoute(props) {
	const navigate = useNavigate();
	const {
		Component,
		children,
		isUsingWhiteBackground,
		user
	} = props
	if (user?.isCheckingInitialAuthData) {
		if (isUsingWhiteBackground) {
			return <WhiteLoadingPage />;
		}
		
		return <AuthenticationBackground />;
	}
	
	if (user?.firebaseUser && user?.tradersUserData) {
		navigate("/game/")
	}
	
	
	return children || <Component />;
}

export default AuthRoute;
