import styled from '@emotion/styled';
// --- external imports ------

import SmallOrangeFormBg from '~/assets/images/auth/small-orange-form-bg.png';
// --- internal imports ------

const SmallOrangeForm = styled.form`
	position: relative;
	width: ${SmallOrangeFormBg.width}px;
	height: ${SmallOrangeFormBg.height}px;
	background-image: url(${SmallOrangeFormBg.src});
	z-index: 1;
`;

export default SmallOrangeForm;
