import styled from '@emotion/styled';
// --- external imports ------

const WhiteLoadingPage = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	z-index: 1;
`;

export default WhiteLoadingPage;
