import styled from '@emotion/styled';
// --- external imports ------

const Header = styled.h1`
	text-shadow: -4px 4px 0 #d63b25;
	color: #f2c218;
	font-family: 'Keys of Paradise';
	font-weight: normal;
	line-height: 1em;
	font-size: 64px;
	user-select: none;
`;

export default Header;
