
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// --- external imports ------

import { UserProvider } from '~/authentication'

import * as serviceWorker from './serviceWorker';
import App from './App';
import GTMProvider from './analytics/GTMProvider';
// --- local imports ------

const tradersTracesSampleRate =
	process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
		? 0.25
		: 1.0;

Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: tradersTracesSampleRate,
		environment: process.env.NODE_ENV
	});
	
	export const root = ReactDOM.createRoot(document.getElementById('root'))
	root.render(
	<React.StrictMode>
		<UserProvider>
			<GTMProvider>
				<App />
			</GTMProvider>
		</UserProvider>
	</React.StrictMode>,
	);
	
	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
