import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// --- external imports ------

import firebaseConfig from '~/authentication/lib/config/firebaseConfig';
// --- internal imports ------

firebase.initializeApp(firebaseConfig);

export const persistence = firebase.auth.Auth.Persistence;

export default firebase.auth();
