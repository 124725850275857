import { 
	NAME_MIN_LENGTH,
	NAME_MAX_LENGTH,
	PASSWORD_MIN_LENGTH
} from './constants-auth';
// --- local imports ------

export const VALIDATION_ERRORS = {
	NAMES: {
		MUST_BE_STRING: 'Must be a string.',
		MUST_CONTAIN_ALPHA: 'Must contain alphabet characters.',
		TOO_SHORT: `Must be at least ${NAME_MIN_LENGTH} letters.`,
		TOO_LONG: `Must be at most ${NAME_MAX_LENGTH} letters.`
	},
	PASSWORDS: {
		MUST_BE_STRING: 'Password must be a string!',
		TOO_SHORT: `Password must be at least ${PASSWORD_MIN_LENGTH} characters long!`,
		MUST_CONTAIN_NUMBER: 'Password must contain a number!',
		MUST_CONTAIN_LOWERCASE: 'Password must contain a lowercase character!',
		MUST_CONTAIN_UPPERCASE: 'Password must contain an uppercase character!',
		CONFIRM_NO_MATCH: 'This field must be the same as the other password field!'
	},
	EMAIL: {
		REQUIRED: 'You must input an email!',
		INVALID: 'Email must be valid!',
		OPTIONAL_INVALID: 'You must input a valid email or nothing at all!'
	}
};

export default VALIDATION_ERRORS;
