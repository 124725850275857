import { FIREBASE_ERROR_CODES } from './errors-firebase';
// --- local imports ------

function handleGenericError(error) {
	if (error && error.message) {
		console.error(error);
		return error.message;
	}

	return null;
}

export const ERROR_CODES = {
	FIREBASE: FIREBASE_ERROR_CODES
};

export function handleBaseError(error) {
	const genericError = handleGenericError(error);
	if (genericError !== null) {
		return genericError;
	}

	return 'Unknown Error!';
}
