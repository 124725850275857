import React from 'react';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,  
};

TagManager.initialize(tagManagerArgs);

const GTMProvider = ({ children }) => {
    return <>{children}</>;
};

export default GTMProvider;
